import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/black-logo-with-text.svg";
import { axiosGet, axiosPost } from "../../helpers/Axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import RightTick from "../../assets/images/check.svg";

const VerifyEmail = (props) => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [sentEmail, isSentEmail] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [validateCaptcha, setValidateCaptcha] = useState(false);
  const [currentCountValue, setCurrentCountValue] = useState(0);

  const countValue = sessionStorage.getItem("countValue");

  useEffect(() => {
    if (countValue) {
      setCurrentCountValue(countValue);
    }
    console.log("");
  }, [countValue]);

  const onChangeRecaptcha = (value) => {
    // token = value;
    setValidateCaptcha(true);
    sessionStorage.setItem("countValue", 0);
    console.log("onChangeRecaptcha", JSON.stringify(value));
  };

  // useEffect(() => {
  //   setTimeout(() => isSentEmail(true), 3000)
  // }, [])

  useEffect(() => {
    if (sentEmail) {
      setTimeout(() => isSentEmail(false), 7000);
    }
  }, [sentEmail]);

  const onClickSubmit = () => {
    if (!validateCaptcha && parseInt(countValue) > 2) {
      props.setSnackbarState({
        message: "Please complete captcha verification.",
        severity: "error",
        open: true,
      });
    } else if (email) {
      axiosPost("api/resend", { email })
        .then((response) => {
          if (response && response.code === "success") {
            isSentEmail(true);
          }
        })
        .catch((err) => {
          console.log("error -- ", err);
        });
    }
    if (countValue)
      sessionStorage.setItem("countValue", parseInt(countValue) + 1);
    else
      sessionStorage.setItem("countValue", 1);

    console.log("CountValue ", countValue);
  };

  useEffect(() => {
    if (isValidated) {
      navigate("/thank-you");
    }
  }, [navigate,isValidated]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer((prev) => prev - 1), 1000);
    } else if (email) {
      axiosGet("api/user-details/" + email)
        .then((response) => {
          if (response && response.status === 200) {
            const { userKey } = response.result;
            localStorage.setItem("userKey", userKey);
            setIsValidated(true);
          } else if (response && response.code === "not_found") {
            localStorage.clear();
            navigate("/");
          } else {
            setTimer(5);
          }
          console.log("Response : ", response);
        })
        .catch((err) => {
          console.log("Error --- ", err);
        });
    }
  }, [email,navigate,timer]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Expand Network - Verify Email</title>
      </Helmet>
      <Grid
        container
        style={{
          height: "auto",
          backgroundColor: "linear-gradient(270deg, #338CF5 0%, #D0E6FF 51.01%, #338CF5 100%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Grid
          item
          xs={12}
          md={10}
          style={{ textAlign: "center", marginTop: 50 }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => window.location.replace("https://expand.network/")}
          >
            <img src={Logo} alt="logo" width="187.5px" height="40px" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "0px 20px",
            minHeight: window.innerHeight - 110,
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            component={Paper}
            style={{
              textAlign: "left",
              marginTop: 30,
              borderRadius: 5,
              padding: "20px 32px",
              background: "#F9F9FB",
              maxWidth: "498px",
            }}
          >
            <Typography variant="p" sx={{ color: "#52555B" }}>
              An email has been sent to{" "}
              <span>
                <Typography
                  variant="p"
                  sx={{ fontWeight: "800 !important", color: "#52555B" }}
                >
                  {email}
                </Typography>
              </span>
              . <br />
              Click the link in the email to complete signup.
            </Typography>
            <br />
            <br />
            {parseInt(currentCountValue) > 2 && (
              <>
                <ReCAPTCHA
                  onChange={onChangeRecaptcha}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                />
                <br />
              </>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{
                padding: "10px",
                backgroundColor: "#338cf5",
                color: "#fff",
                textTransform: "inherit",
                fontSize: "16px",
                fontWeight: "500",
                height: "48px",
              }}
              onClick={onClickSubmit}
            >
              <Box>Resend email</Box>
            </Button>
            <br />
            {sentEmail && (
              <span
                style={{
                  // float: "left",
                  color: "red",
                  fontWeight: 500,
                  fontFamily: "Roboto",
                  paddingTop: 15,
                  // paddingBottom: 10,
                  color: "#338cf5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <br />
                <img src={RightTick} alt="logo" />
                Email resent.
              </span>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100vw",
            height: 80,
            position: "absolute",
            bottom: 0,
            alignItems: "center",
          }}
        >
          <Typography
            variant="p"
            style={{ color: "#000", fontSize: 12, fontWeight: 500 }}
          >
            © 2025 expand.network. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default VerifyEmail;
