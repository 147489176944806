import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import Logo from "../../assets/images/black-logo-with-text.svg";

const AlreadySignup = () => {
  return (
    <Grid
      container
      style={{
        // height: "100vh",
        backgroundColor: "linear-gradient(270deg, #338CF5 0%, #D0E6FF 51.01%, #338CF5 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Grid item xs={12} md={12} style={{ textAlign: "center", marginTop: 50 }}>
        <Typography
          variant="h4"
          sx={{
            cursor: "pointer",
            color: "#fff",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => window.location.replace("https://expand.network/")}
        >
          <img src={Logo} alt="logo" width="187.5px" height="40px" />
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "0px 10px",
          minHeight: window.innerHeight - 100,
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          component={Paper}
          style={{
            textAlign: "center",
            marginTop: 30,
            borderRadius: 10,
            background: "#F9F9FB",
            padding: "20px 32px",
            maxWidth: "498px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontWeight: 400,
              color: "#000",
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              paddingTop: "10px",
              //paddingBottom: "10px",
            }}
          >
            If there is an account with this email, the key will be emailed to
            you.
          </Typography>
          <br />
          <a
            href="https://docs.expand.network/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // className={classes.visitBtn}
              style={{
                padding: "10px",
                backgroundColor: "#338cf5",
                color: "#fff",
                textTransform: "inherit",
                fontSize: "16px",
                fontWeight: "500",
                height: "48px",
              }}
              size="large"
            >
              <Box> Visit our Documentation</Box>
            </Button>
          </a>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100vw",
          height: 80,
          position: "absolute",
          bottom: 0,
          alignItems: "center",
        }}
      >
        <Typography
          variant="p"
          style={{ color: "#000", fontSize: 12, fontWeight: 500 }}
        >
          © 2025 expand.network. All Rights Reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AlreadySignup;
