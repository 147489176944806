import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/black-logo-with-text.svg";
import { axiosPost, axiosGet } from "../../helpers/Axios";
import "../../index.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google";
import "../../App.css";
import SingupStyles from "./SignupStyles";
import axios from "axios";

const BACKGROUND = "#FAEAEA";
const BORDER_COLOR = "1px solid #EB5D44";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Signup = (props) => {
  const navigate = useNavigate();
  const classes = SingupStyles();
  const [checked, setChecked] = useState(false);
  const [dynamicWidth, setDynamicWidth] = useState("300px");
  const [validateCaptcha, setValidateCaptcha] = useState(false);
  const [sentRequest, isSentRequest] = useState(false);
  const [tokenResponse, setTokenResponse] = useState(null);
  const [user, setUser] = useState(null);
  const [usageType, setUsageType] = useState("Work");

  const handleUsageChange = (event) => {
    setUsageType(event.target.value);
  };

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    linkedinId: "",
    telegramId: "",
    twitterId: "",
    githubId: "",
    additionalInfo: "",
  });

  useEffect(() => {
    sessionStorage.setItem("countValue", 0);
    if (window.innerWidth > 537) {
      setDynamicWidth("498px");
    } else {
      setDynamicWidth(window.innerWidth - 20 + "px");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      if (window.innerWidth > 537) {
        setDynamicWidth("498px");
      } else {
        setDynamicWidth(window.innerWidth - 30 + "px");
      }
    });
  });

  const [errorFlag, setErrorFlag] = useState({
    firstname: false,
    lastname: false,
    email: false,
    firstnameBorder: false,
    lastnameBorder: false,
    emailBorder: false,
    companyBorder: false,
    checkbox: false,
    captcha: false,
    linkedinId: false,
    telegramId: false,
    twitterId: false,
    githubId: false,
    additionalInfo: false,
    additionalInfoBorder: false,
  });

  const onChangeRecaptcha = (value) => {
    setValidateCaptcha(true);
    setErrorFlag({
      ...errorFlag,
      captcha: false,
    });
  };

  const handleChange = (e) => {
    let errorFlagData = {
      ...errorFlag,
      [e.target.id]: false,
      [e.target.id + "Border"]: false,
    };

    if (
      (e.target.id === "firstname" || e.target.id === "lastname") &&
      !/^[a-zA-Z]+$/.test(e.target.value)
    ) {
      errorFlagData = {
        ...errorFlagData,
        [e.target.id]: true,
        [e.target.id + "Border"]: true,
      };
    }

    if (e.target.id === "email" && !emailRegex.test(e.target.value)) {
      errorFlagData = {
        ...errorFlagData,
        [e.target.id]: true,
        [e.target.id + "Border"]: true,
      };
    }

    if (
      e.target.id === "linkedinId" &&
      !/^https?:\/\/(www\.)?linkedin\.com/.test(e.target.value)
    ) {
      errorFlagData = {
        ...errorFlagData,
        [e.target.id]: true,
        [e.target.id + "Border"]: true,
      };
    }
    if (
      e.target.id === "telegramId" &&
      !/^$|^\\s+$|^@[a-zA-Z0-9_]{5,32}$/.test(e.target.value)
    ) {
      errorFlagData = {
        ...errorFlagData,
        [e.target.id]: true,
        [e.target.id + "Border"]: true,
      };
    }

    if (
      e.target.id === "twitterId" &&
      !/^$|^@[a-zA-Z0-9]{4,15}$/.test(e.target.value)
    ) {
      errorFlagData = {
        ...errorFlagData,
        [e.target.id]: true,
        [e.target.id + "Border"]: true,
      };
    }

    if (
      e.target.id === "githubId" &&
      !/^\s*(|https:\/\/(www\.)?github\.com\/([a-zA-Z0-9]([a-zA-Z0-9\-]{1,37}[a-zA-Z0-9])?)?)\s*$/.test(
        e.target.value
      )
    ) {
      errorFlagData = {
        ...errorFlagData,
        [e.target.id]: true,
        [e.target.id + "Border"]: true,
      };
    }

    if (
      e.target.id === "additionalInfo" &&
      !/^^(?!\s*$).+$/.test(e.target.value)
    ) {
      errorFlagData = {
        ...errorFlagData,
        [e.target.id]: true,
        [e.target.id + "Border"]: true,
      };
    }

    setErrorFlag({ ...errorFlagData });
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  const handleBlur = () => {
    if (state.email !== "") {
      fetchEmailData(state.email);
    }
  };

  const fetchEmailData = async (email) => {
    try {
      const response = await axiosGet("api/validate-email/" + email);
  
      if (response.status !== 200 || response.data.disposable) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          email: true,
          emailBorder:true
        }));
      }
    } catch (error) {
      console.error("Error fetching email data:", error);
    }
  };

  const checkValidation = () => {
    let flag = true;
    let errorFlagData = {};
    const isValidAlpha = (value) => /^[a-zA-Z]+$/.test(value);
    const isValidEmail = (value) => emailRegex.test(value);
    // const isValidInfo = (value) => /^^(?!\s*$).+$/.test(value)

    if (!state.firstname || !isValidAlpha(state.firstname)) {
      flag = false;
      errorFlagData = {
        ...errorFlagData,
        firstname: true,
        firstnameBorder: true,
      };
    }

    if (!state.lastname || !isValidAlpha(state.lastname)) {
      flag = false;
      errorFlagData = {
        ...errorFlagData,
        lastname: true,
        lastnameBorder: true,
      };
    }

    if (!state.email || !isValidEmail(state.email)) {
      flag = false;
      errorFlagData = {
        ...errorFlagData,
        email: true,
        emailBorder: true,
      };
    }

  
    if (!state.additionalInfo || state.additionalInfo.trim() === "") {
      flag = false;
      errorFlagData = {
        ...errorFlagData,
        additionalInfo: true,
        additionalInfoBorder: true,
      };
    }

    if (!checked) {
      flag = false;
      errorFlagData = {
        ...errorFlagData,
        checked: true,
      };
    }

    if (!validateCaptcha && flag) {
      flag = false;
      errorFlagData = {
        ...errorFlagData,
        captcha: true,
      };
    }

    setErrorFlag({ ...errorFlagData });
    return flag;
  };

  const onClickSubmit = () => {
    if (checkValidation() && !sentRequest) {
      isSentRequest(true);

      axiosPost("api/signup", state)
        .then((response) => {
          // console.log("Response -- ", response);
          if (response) {
            if (response && response.code === "already_exists") {
              navigate("/signup");
            } else if (response && response.code === "success") {
              localStorage.setItem("email", state.email);
              // localStorage.setItem("url", response?.url);
           
              navigate("/verify-email");
            }
          }
          isSentRequest(false);
        })
        .catch((err) => {
          console.log("Error --- ", err);
          isSentRequest(false);
        });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      state.firstname &&
      state.lastname &&
      state.email &&
      state.additionalInfo &&
      checked
    ) {
      onClickSubmit(e);
    } 
  };

  const responseGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      console.log("inside response google");
      setTokenResponse(tokenResponse);
      setUser(userInfo);

      axiosPost("api/google-signup", {
        userInfo,
      }).then((res) => {
        if (res.code === "success") {
          localStorage.setItem("keyId", res.keyId);
          localStorage.setItem("userKey", res.userKey);
          navigate("/thank-you");
        } else if (res.code === "already_exists") {
          navigate("/signup");
        }
      });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <Grid
      container
      style={{
        height: "100vh",
        backgroundColor:
          "linear-gradient(270deg, #338CF5 0%, #D0E6FF 51.01%, #338CF5 100%)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12} md={12} style={{ textAlign: "center", marginTop: 50 }}>
        <Typography
          variant="h4"
          sx={{
            color: "#fff",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => window.location.replace("https://expand.network/")}
        >
          <img src={Logo} alt="logo" width="187.5px" height="auto" />
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "0px 20px",
        }}
      >
        <Grid
          item
          xxs={12}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          component={Paper}
          style={{
            textAlign: "center",
            marginTop: 30,
            minHeight: window.innerHeight - 250,
            borderRadius: 10,
            background: "#F9F9FB",
            maxWidth: dynamicWidth, //'498px',
          }}
        >
          <Typography className={classes.headingStyls}>Get API Key</Typography>
          <Box style={{ padding: "20px 32px 32px" }}>
            <form onSubmit={handleFormSubmit}>
              <FormControl
                margin="normal"
                required
                fullWidth
                style={{ justifyContent: "left" }}
              >
                <label
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    marginBottom: 5,
                    color: "#52555B",
                  }}
                >
                  FIRST NAME
                </label>
                <TextField
                  placeholder="Vitalik"
                  variant="outlined"
                  id="firstname"
                  name="firstname"
                  type="text"
                  value={state.firstname}
                  inputProps={{
                    maxLength: 72,
                    sx: { height: 15 },
                    required: true,
                  }}
                  onChange={(event) => handleChange(event)}
                  onFocus={() =>
                    setErrorFlag({ ...errorFlag, firstnameBorder: false })
                  }
                  style={{
                    borderRadius: 4,
                    backgroundColor: errorFlag.firstname ? BACKGROUND : "#fff",
                    border: errorFlag.firstnameBorder
                      ? BORDER_COLOR
                      : "#E0E0E0",
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#338cf5",
                      },
                      "&:focus": {
                        border: "#338cf5",
                      },
                    },
                  }}
                />
                {errorFlag.firstname && (
                  <span
                    style={{
                      float: "left",
                      color: "red",
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      marginTop: 5,
                    }}
                  >
                    Please enter only alphabetic characters.
                  </span>
                )}
              </FormControl>
              <br />

              <FormControl
                margin="normal"
                required
                fullWidth
                style={{ justifyContent: "left" }}
              >
                <label
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    marginBottom: 5,
                    color: "#52555B",
                  }}
                >
                  LAST NAME
                </label>
                <TextField
                  placeholder="Buterin"
                  variant="outlined"
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={state.lastname}
                  inputProps={{
                    maxLength: 72,
                    sx: { height: 15 },
                    required: true,
                  }}
                  onChange={(event) => handleChange(event)}
                  onFocus={() =>
                    setErrorFlag({ ...errorFlag, lastnameBorder: false })
                  }
                  style={{
                    borderRadius: 4,
                    backgroundColor: errorFlag.lastname ? BACKGROUND : "#fff",
                    border: errorFlag.lastnameBorder ? BORDER_COLOR : "#E0E0E0",
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#338cf5",
                      },
                    },
                  }}
                />
                {errorFlag.lastname && (
                  <span
                    style={{
                      float: "left",
                      color: "red",
                      fontWeight: 500,
                      fontFamily: "Roboto",
                      marginTop: 5,
                    }}
                  >
                    Please enter only alphabetic characters.
                  </span>
                )}
              </FormControl>
              <br />

              <FormControl
                component="fieldset"
                margin="normal"
                required
                fullWidth
                style={{ justifyContent: "left" }}
                size="normal"
              >
                <label
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    marginBottom: 5,
                    color: "#52555B",
                  }}
                >
                  Usage Type
                </label>
                <RadioGroup
                  row
                  name="usageType"
                  value={usageType}
                  onChange={handleUsageChange}
                >
                  <FormControlLabel
                    value="Work"
                    control={<Radio />}
                    label="Work"
                  />
                  <FormControlLabel
                    value="Personal"
                    control={<Radio />}
                    label="Personal Use"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              {usageType === "Work" && (
                <form>
                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      COMPANY NAME
                    </label>
                    <TextField
                      placeholder="XYZ Pvt. Ltd"
                      variant="outlined"
                      id="company"
                      name="company"
                      type="text"
                      value={state.company}
                      inputProps={{
                        maxLength: 72,
                        sx: { height: 15 },
                        required: true,
                      }}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, companyBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.company
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.companyBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    style={{ justifyContent: "left" }}
                    size="normal"
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      COMPANY EMAIL
                    </label>
                    <TextField
                      placeholder="vitalik@buterin.com"
                      variant="outlined"
                      id="email"
                      name="email"
                      type="email"
                      value={state.email}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, emailBorder: false })
                      }
                      onBlur={handleBlur}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        borderRadius: 4,
                        backgroundColor: errorFlag.email ? BACKGROUND : "#fff",
                        border: errorFlag.emailBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                    {errorFlag.email && (
                      <span
                        style={{
                          float: "left",
                          color: "red",
                          fontWeight: 500,
                          fontFamily: "Roboto",
                          marginTop: 5,
                        }}
                      >
                        Please use a valid email. If you are having issues,
                        please reach out to info@expand.network
                      </span>
                    )}
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    required
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      LINKEDIN URL
                    </label>
                    <TextField
                      placeholder="https://www.linkedin.com/xyz/"
                      variant="outlined"
                      id="linkedinId"
                      name="linkedinId"
                      type="url"
                      value={state.linkedinId}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, linkedinIdBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.linkedinId
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.linkedinIdBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      inputProps={{
                        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      TELEGRAM USERNAME
                    </label>
                    <TextField
                      placeholder="@xyz"
                      variant="outlined"
                      id="telegramId"
                      name="telegramId"
                      type="text"
                      value={state.telegramId}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, telegramIdBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.telegramId
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.telegramIdBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      inputProps={{
                        pattern: "^$|^\\s+$|^@[a-zA-Z0-9_]{5,32}$",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      TWITTER HANDLE
                    </label>
                    <TextField
                      placeholder="@xyz"
                      variant="outlined"
                      id="twitterId"
                      name="twitterId"
                      type="text"
                      value={state.twitterId}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, twitterIdBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.twitterId
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.twitterIdBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      inputProps={{
                        sx: { height: 15 },
                        pattern: "^$|^@[a-zA-Z0-9]{4,15}$",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    required
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      How are you planning to use expand.network
                    </label>
                    <TextField
                      placeholder="Enter any additional details here..."
                      variant="outlined"
                      id="additionalInfo"
                      name="additionalInfo"
                      type="text"
                      value={state.additionalInfo}
                      multiline
                      rows={2}
                      inputProps={{
                        maxLength: 500,
                        required: true,
                      }}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({
                          ...errorFlag,
                          additionalInfoBorder: false,
                          additionalInfo: false,
                        })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.additionalInfo
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.additionalInfoBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                    {errorFlag.additionalInfoBorder && (
                      <span
                        style={{
                          float: "left",
                          color: "red",
                          fontWeight: 500,
                          fontFamily: "Roboto",
                          marginTop: 5,
                        }}
                      >
                        Please enter additional details.
                      </span>
                    )}
                  </FormControl>
                </form>
              )}
              {usageType === "Personal" && (
                <form>
                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    style={{ justifyContent: "left" }}
                    size="normal"
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      EMAIL
                    </label>
                    <TextField
                      placeholder="vitalik@buterin.com"
                      variant="outlined"
                      id="email"
                      name="email"
                      type="email"
                      value={state.email}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, emailBorder: false })
                      }
                      onBlur={handleBlur}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        borderRadius: 4,
                        backgroundColor: errorFlag.email ? BACKGROUND : "#fff",
                        border: errorFlag.emailBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      inputProps={{
                        sx: { height: 15 },
                        required: true,
                        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                    {errorFlag.email && (
                      <span
                        style={{
                          float: "left",
                          color: "red",
                          fontWeight: 500,
                          fontFamily: "Roboto",
                          marginTop: 5,
                        }}
                      >
                        Please use a valid email. If you are having issues,
                        please reach out to info@expand.network
                      </span>
                    )}
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      GITHUB URL
                    </label>
                    <TextField
                      placeholder="https://github.com/xyz"
                      variant="outlined"
                      id="githubId"
                      name="githubId"
                      type="url"
                      value={state.githubId}
                      inputProps={{
                        maxLength: 72,
                        sx: { height: 15 },
                        pattern: !/^https?:\/\/(www\.)?github\.com/,
                      }}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, githubIdBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.githubId
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.githubIdBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      LINKEDIN URL
                    </label>
                    <TextField
                      placeholder="https://www.linkedin.com/xyz/"
                      variant="outlined"
                      id="linkedinId"
                      name="linkedinId"
                      type="url"
                      value={state.linkedinId}
                      inputProps={{
                        maxLength: 72,
                        sx: { height: 15 },
                        required: true,
                      }}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, linkedinIdBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.linkedinId
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.linkedinIdBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      TELEGRAM USERNAME
                    </label>
                    <TextField
                      placeholder="@xyz"
                      variant="outlined"
                      id="telegramId"
                      name="telegramId"
                      type="text"
                      value={state.telegramId}
                      inputProps={{
                        maxLength: 72,
                        pattern: "@[a-z0-9._%+-]",
                      }}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, telegramIdBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.telegramId
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.telegramIdBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      TWITTER HANDLE
                    </label>
                    <TextField
                      placeholder="@xyz"
                      variant="outlined"
                      id="twitterId"
                      name="twitterId"
                      type="text"
                      value={state.twitterId}
                      inputProps={{
                        sx: { height: 15 },
                        pattern: "^$|^@[a-zA-Z0-9]{4,15}$",
                      }}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({ ...errorFlag, twitterIdBorder: false })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.twitterId
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.twitterIdBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    required
                    style={{ justifyContent: "left" }}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#52555B",
                      }}
                    >
                      How are you planning to use expand.network
                    </label>
                    <TextField
                      placeholder="Enter any additional details here..."
                      variant="outlined"
                      id="additionalInfo"
                      name="additionalInfo"
                      type="text"
                      value={state.additionalInfo}
                      multiline
                      rows={2}
                      inputProps={{
                        maxLength: 500,
                        required: true,
                      }}
                      onChange={(event) => handleChange(event)}
                      onFocus={() =>
                        setErrorFlag({
                          ...errorFlag,
                          additionalInfoBorder: false,
                          additionalInfo: false,
                        })
                      }
                      style={{
                        borderRadius: 4,
                        backgroundColor: errorFlag.additionalInfo
                          ? BACKGROUND
                          : "#fff",
                        border: errorFlag.additionalInfoBorder
                          ? BORDER_COLOR
                          : "#E0E0E0",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#338cf5",
                          },
                        },
                      }}
                    />
                    {errorFlag.additionalInfoBorder && (
                      <span
                        style={{
                          float: "left",
                          color: "red",
                          fontWeight: 500,
                          fontFamily: "Roboto",
                          marginTop: 5,
                        }}
                      >
                        Please enter additional details.
                      </span>
                    )}
                  </FormControl>
                </form>
              )}
              <br />

              <Typography
                align="left"
                component="p"
                variant="body1"
                sx={{ paddingLeft: 0, fontSize: "12px" }}
              >
                <Checkbox
                  required
                  color="primary"
                  onClick={(e) => setChecked(!checked)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  sx={{
                    "&.MuiCheckbox-root": {
                      color: errorFlag.checked ? "#EB5D44" : "#c4c4c4",
                    },
                    "&.Mui-checked": {
                      color: "#338cf5",
                    },
                  }}
                  style={{
                    marginLeft: "-10px",
                    borderColor: "#E0E0E0",
                    fontSize: "10px",
                    height: "40px",
                  }}
                  value={checked}
                />
                Accept{" "}
                <a
                  href="https://expand.network/terms-of-services/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#338cf5",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Terms & Conditions
                </a>
              </Typography>
              <br />
              <ReCAPTCHA
                className={classes.recaptchaStyle}
                onChange={onChangeRecaptcha}
                sitekey={process.env.REACT_APP_SITE_KEY}
                size="normal"
              />
              <br />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                id="signupButton"
                style={{
                  padding: "10px",
                  backgroundColor: "#338cf5",
                  color: "#fff",
                  textTransform: "inherit",
                  fontSize: "16px",
                  fontWeight: "500",
                  height: "48px",
                  cursor: sentRequest ? "wait" : "pointer",
                }}
                onClick={() => {
                  !sentRequest ? onClickSubmit() : console.log("Wait");
                }}
                //  disabled={sentRequest}
              >
                <Box>Get API Key</Box>
              </Button>
              {errorFlag.captcha && (
                <span
                  style={{
                    float: "left",
                    color: "red",
                    fontWeight: 500,
                    fontFamily: "Roboto",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Please complete reCAPTCHA verification.
                </span>
              )}
            </form>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100vw",
          height: 80,
          position: "relative",
          bottom: 0,
          alignItems: "center",
        }}
      >
        <Typography
          variant="p"
          style={{ color: "#000", fontSize: 12, fontWeight: 500 }}
        >
          © 2025 expand.network. All Rights Reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Signup;
